<template>
	<v-card flat tile :loading="rewardsLoading" class="rounded-b-xl">
		<v-card-title>
			<h3>Rewards - {{ rewards.length }} Total</h3>
			<v-dialog v-model="dialog" max-width="950px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" small fab v-bind="attrs" v-on="on" color="pink" class="mx-3">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</template>
				<NewReward 
					:streamers="$store.state.streamers" 
					@NewRewardAdded="UpdateRewards" 
					@CloseDialog="dialog = false"
				/>
			</v-dialog>
		</v-card-title>

		<v-row class="pa-5 pt-0">
			<v-col cols="12" v-for="reward in rewards" :key="reward.id">
				<EditReward
					:reward="reward"
					@RewardDeleted="UpdateRewards"
					@RewardUpdated="UpdateRewards"
				/>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import EditReward from './EditReward.vue';
import NewReward from './NewReward.vue';

export default {
	components: {
		NewReward,
		EditReward
	},

	data() {
		return {
			dialog: false,
			rewards: [],
			rewardsLoading: true,
			carousel: 0
		}
	},

	async mounted() {
		await this.fetchRewards();
	},

	methods: {
		UpdateRewards: async function() {
			this.dialog = false;
			await this.fetchRewards();
		},

		async fetchRewards() {
			try {
				const request = await fetch(`${this.$store.state.base_url}/rewards`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				const res = await request.json();

				if (request.ok) {
					if (res.rewards) this.rewards = res.rewards;
				}
				
				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.rewardsLoading = false;
			}
		},
	},
}
</script>